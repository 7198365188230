import React from 'react';
import Layout from 'components/Layout';
import { ReactQueryConfigProvider } from 'react-query';
import Trip from 'cashier/Trip';
export default function operations(props) {
  return (
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Layout {...props}>
        <Trip />
      </Layout>
    </ReactQueryConfigProvider>
  );
}
