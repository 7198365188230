import React from 'react';
import { useQuery } from 'react-query';
import { useDate, useBranchId } from 'utils/customHooks';
import fetch from 'utils/fetch';
import LinearProgress from '@material-ui/core/LinearProgress';
import { formatDateHumanReadable } from 'utils/utils';
import { navigate } from 'components/Link';

function Trip() {
  const date = useDate();
  const nodeId = useBranchId();

  const { data, status } = useQuery(date && nodeId && ['sales_cashier', { date, nodeId }], () =>
    fetch(`/delivery/salesCashier/assignment?nodeId=${nodeId}&date=${date}`)
  );
  if (!data || status === 'loading') return <LinearProgress style={{ width: '100%' }} />;
  return (
    <div class="grid grid-cols-2 lg:grid-cols-3 gap-3 bg-salesGray flex-1 p-2 pb-5">
      {data.map((trip) => (
        <IndividualTrip trip={trip} key={trip.id} />
      ))}
    </div>
  );
}

export default Trip;

function IndividualTrip({ trip }) {
  const { deliveryAgent, picklist, approvedInvoices, totalInvoices } = trip;

  return (
    <div
      className="bg-white rounded-lg shadow-lg mt-2 p-2 cursor-pointer"
      onClick={() => navigate(`/cashier/invoices/${trip.id}`)}
    >
      <div className="flex justify-between items-center">
        <p className="font-semibold">
          {deliveryAgent.name} ({picklist.name})
        </p>
        <p
          className={`${
            approvedInvoices === totalInvoices ? 'bg-green-500' : 'bg-blue-400'
          } font-semibold p-1 px-2 text-white rounded-lg`}
        >
          {approvedInvoices} / {totalInvoices}
        </p>
      </div>
      <p className="font-medium text-inactive whitespace-nowrap">
        {formatDateHumanReadable(picklist.date)}
      </p>
    </div>
  );
}
